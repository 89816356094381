














import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { FormField } from '@/app_code/Forms'
import { Modal, ModalButton } from '@/app_code/Modals/Modal'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { AddPostcodeInZoneModel, PostcodeInZoneModel, ZoneModel } from 'truemarket-modules/src/models/api/admin'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class Edit extends Vue {
  private isAddingZone = false

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.Postcode, [
    new DataTableColDef('Postcode', row => row.Postcode)
  ]))

  private zone: ZoneModel | null = null

  private zoneId = ''

  private postcodes: PostcodeInZoneModel[] = []

  mounted () {
    this.zoneId = this.$route.params.id

    Services.API.Admin.Zones.GetZone(this.zoneId).then((zone) => {
      this.zone = zone
    })

    this.updatePostcodes()
  }

  updatePostcodes () {
    Services.API.Admin.Zones.GetPostcodes(this.zoneId).then((postcodes) => {
      this.postcodes = postcodes
    })
  }

  addPostcode () {
    const modal = new Modal({
      Heading: 'Add Postcode',
      FormFields: [
        new FormField({ Type: 'text', Placeholder: 'E.g. 4870', Label: 'Postcode', Key: 'Postcode' })
      ],
      Buttons: [
        new ModalButton({
          Text: 'Cancel',
          Type: 'subtle',
          Invert: true,
          OnClick: () => {
            modal.Hide()
          }
        }),
        new ModalButton({
          Text: 'Save',
          OnClick: () => {
            modal.GetFormValues().then(vals => {
              const model = vals.BuildModel<AddPostcodeInZoneModel>()

              Services.API.Admin.Zones.AddPostcode(this.zoneId, model.Postcode).then(() => {
                modal.Hide()
                this.updatePostcodes()
              })
            })
          }
        })
      ]
    })

    modal.Show()
  }

  deletePostcode (postcode: string) {
    if (this.zone === null) return

    Modal.Confirm({ Content: `Are you sure you want to remove the postcode "${postcode}" from "${this.zone.TMName}"?` }).then(() => {
      Services.API.Admin.Zones.DeletePostcode(this.zoneId, postcode).then(() => {
        this.updatePostcodes()
      })
    })
  }
}
